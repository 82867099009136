import React from "react";
import Page from "./components/Page"; // Adjust the path as per your directory structure
import "./App.css"; // Import global styles if needed

const App = () => {
  return (
    <div className="App">
      <Page />
    </div>
  );
};

export default App;
