import React from "react";
import styles from "./page.module.css";

const Page = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1>Rohan Mathew</h1>
        <h2>Software Engineer</h2>
        <div className={styles.mainContent}>
          <h1>About</h1>
          <div className={styles.childInfo}>
            <p>
              I'm a 22 year old Computer Science student at Georgia Tech. I'm
              interested in building cool products using technology
            </p>
          </div>
          <h1>Today</h1>
          <div className={styles.childInfo}>
            <p>
              Looking for exciting opportunities in the Finance/Technology
              sectors
            </p>
          </div>
          <h1>Previously</h1>
          <div className={styles.childInfo}>
            <ul>
              <li>Teaching assistant for CS 2340 at Georgia Tech</li>
              <li>
                Software Application Development Engineer Intern at Workday
              </li>
              <li>Software Engineer Intern at State Farm</li>
              <li>Software Developer for a Georgia Tech SaaS Startup</li>
            </ul>
          </div>
          <h1>Contact</h1>
          <div className={styles.childInfo}>
            <p>
              If you'd like to connect, find me at rmathew [at] gatech.edu or
              linkedin.com/in/~rohan
            </p>
          </div>
          <h1>Misc</h1>
          <div className={styles.childInfo}>
            <ul>
              <li>
                Used to resell sneakers and streetwear and electronics in high
                school
              </li>
              <li>
                Recently got into weightlifting, I want to squat 3 plates by the
                end of the semester
              </li>
              <li>
                Interested in all things related to cars, specifically German
                and Italian ones
              </li>
              <li>Top 3 Artists: Travis Scott, Don Toliver, and Gunna</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page;
